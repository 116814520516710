import React from "react"

import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import ContactsIcon from "@mui/icons-material/Contacts"
import CorporateFareIcon from "@mui/icons-material/CorporateFare"
import FactoryIcon from "@mui/icons-material/Factory"
import HomeIcon from "@mui/icons-material/Home"
import InventoryIcon from "@mui/icons-material/Inventory"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import PeopleIcon from "@mui/icons-material/People"
import RedeemIcon from "@mui/icons-material/Redeem"
import SettingsIcon from "@mui/icons-material/Settings"
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import TableChartIcon from "@mui/icons-material/TableChart"
import WarehouseIcon from "@mui/icons-material/Warehouse"

import routes, {
  inventorySubRoutes,
  itemSubRoutes,
  jobSubRoutes,
  moveInventorySubRoutes,
  receivingSubRoutes,
  shippingModalSubRoutes,
  shippingSubRoutes,
  warehouseLocationSubRoutes,
} from "@constants/routes"
import { itemTypes } from "@constants/serverEnums"

export const hubs = {
  HOME: "home",
  FINANCE: "finance",
  OPERATIONS: "operations",
  PEOPLE: "people",
  SALES: "sales",
  SETTINGS: "settings",
  REPORTING: "reporting",
}

export const features = {
  COMPANIES: "companies",
  CONTACTS: "contacts",
  CUSTOMERS: "customers", // deprecated
  ITEMS: "items",
  INVENTORY: "inventory",
  MATERIALS: "materials", // deprecated
  ORDERS: "orders",
  QUOTES: "quotes",
  POS: "purchase_orders",
  PRODUCTION: "production",
  PRODUCTS: "products", // deprecated
  RECEIVING: "receiving",
  REPORTS: "reports",
  SHIPPING: "shipping",
  USERS: "users",
  VENDORS: "vendors", // deprecated
}

export interface FeatureNavItem {
  icon: React.ReactNode
  route: string
  translatedTextKey: string
  show?: string
}

export interface FeatureNavs {
  [key: string]: FeatureNavItem
}

// all top level routes
export const featureNavs: FeatureNavs = {
  companies: {
    icon: <CorporateFareIcon />,
    route: routes.COMPANIES,
    translatedTextKey: "features_companies",
  },
  contacts: {
    icon: <ContactsIcon />,
    route: routes.CONTACTS,
    translatedTextKey: "features_contacts",
  },
  customFields: {
    icon: <SettingsIcon />,
    route: routes.CUSTOM_FIELDS,
    translatedTextKey: "features_customFields",
  },
  customLabels: {
    icon: <SettingsIcon />,
    route: routes.CUSTOM_LABELS,
    translatedTextKey: "features_customLabels",
  },
  customDocs: {
    icon: <SettingsIcon />,
    route: routes.CUSTOM_DOCS,
    translatedTextKey: "features_customDocs",
  },
  customForms: {
    icon: <SettingsIcon />,
    route: routes.CUSTOM_FORMS,
    translatedTextKey: "features_customForms",
  },
  dashboard: {
    icon: <HomeIcon />,
    route: routes.DASHBOARD,
    translatedTextKey: "features_scorecard",
  },
  integrations: {
    icon: <SettingsIcon />,
    route: routes.INTEGRATIONS,
    translatedTextKey: "features_integrations",
  },
  inventory: {
    icon: <InventoryIcon />,
    route: routes.INVENTORY,
    translatedTextKey: "features_inventory",
  },
  items: {
    icon: <RedeemIcon />,
    route: routes.ITEMS,
    translatedTextKey: "features_items",
  },
  operations: {
    icon: <SettingsIcon />,
    route: routes.OPERATIONS,
    translatedTextKey: "features_operations",
  },
  orders: {
    icon: <ShoppingBagIcon />,
    route: routes.ORDERS,
    translatedTextKey: "features_orders",
  },
  quotes: {
    icon: <CalculateOutlinedIcon />,
    route: routes.QUOTES,
    translatedTextKey: "features_quotes",
  },
  permissionsBilling: {
    icon: <SettingsIcon />,
    route: routes.PERMISSIONS_AND_BILLING,
    translatedTextKey: "features_permissionsAndBilling",
  },
  production: {
    icon: <FactoryIcon />,
    route: routes.PRODUCTION,
    translatedTextKey: "features_production",
  },
  purchase_orders: {
    icon: <ShoppingCartIcon />,
    route: routes.PURCHASE_ORDERS,
    translatedTextKey: "features_purchaseOrders",
  },
  receiving: {
    icon: <WarehouseIcon />,
    route: routes.RECEIVING,
    translatedTextKey: "features_receiving",
  },
  reports: {
    icon: <TableChartIcon />,
    route: routes.REPORTS,
    translatedTextKey: "features_reports",
  },
  settings: {
    icon: <SettingsIcon />,
    route: routes.SETTINGS,
    translatedTextKey: "features_general",
  },
  shipping: {
    icon: <LocalShippingIcon />,
    route: routes.SHIPPING,
    translatedTextKey: "features_shipping",
  },
  tools: {
    icon: <SettingsIcon />,
    route: routes.TOOLS,
    translatedTextKey: "features_tools",
  },
  users: {
    icon: <PeopleIcon />,
    route: routes.USERS,
    translatedTextKey: "features_users",
  },
  warehouseLocations: {
    icon: <SettingsIcon />,
    route: routes.WAREHOUSE_LOCATIONS,
    translatedTextKey: "features_warehouseLocations",
  },
}
export const itemSubNavs: FeatureNavs = {
  itemDetails: {
    icon: null,
    route: itemSubRoutes.ITEM_DETAILS,
    translatedTextKey: "features_itemSubNavs_itemDetails",
  },
  itemVariants: {
    icon: null,
    route: itemSubRoutes.ITEM_VARIANTS,
    translatedTextKey: "features_itemSubNavs_itemVariants",
    show: "hasVariants",
  },
  itemVendors: {
    icon: null,
    route: itemSubRoutes.ITEM_VENDORS,
    translatedTextKey: "features_itemSubNavs_itemVendors",
    show: itemTypes.BUY,
  },
  itemPurchasing: {
    icon: null,
    route: itemSubRoutes.ITEM_PURCHASING,
    translatedTextKey: "features_itemSubNavs_itemPurchasing",
    show: itemTypes.BUY,
  },
  itemProduction: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION,
    translatedTextKey: "features_itemSubNavs_itemProduction",
    show: itemTypes.MAKE,
  },
  itemProductionBom: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_BOM,
    translatedTextKey: "features_itemSubNavs_itemProductionBom",
  },
  itemProductionRouting: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_ROUTING,
    translatedTextKey: "features_itemSubNavs_itemProductionRouting",
  },
  itemProductionHistory: {
    icon: null,
    route: itemSubRoutes.ITEM_PRODUCTION_HISTORY,
    translatedTextKey: "features_itemSubNavs_itemProductionHistory",
  },
  itemInventory: {
    icon: null,
    route: itemSubRoutes.ITEM_INVENTORY,
    translatedTextKey: "features_itemSubNavs_itemInventory",
  },
  itemSales: {
    icon: null,
    route: itemSubRoutes.ITEM_SALES,
    translatedTextKey: "features_itemSubNavs_itemSales",
    show: itemTypes.SELL,
  },
  itemAutomations: {
    icon: null,
    route: itemSubRoutes.ITEM_AUTOMATIONS,
    translatedTextKey: "features_itemSubNavs_itemAutomations",
  },
}
export const jobSubNavs: FeatureNavs = {
  jobDetails: {
    icon: null,
    route: jobSubRoutes.JOB_DETAILS,
    translatedTextKey: "features_jobSubNavs_jobDetails",
  },
  jobProduction: {
    icon: null,
    route: jobSubRoutes.JOB_PRODUCTION,
    translatedTextKey: "features_jobSubNavs_jobProduction",
  },
  jobMaterials: {
    icon: null,
    route: jobSubRoutes.JOB_MATERIALS,
    translatedTextKey: "features_jobSubNavs_jobMaterials",
  },
  jobQuality: {
    icon: null,
    route: jobSubRoutes.JOB_QUALITY,
    translatedTextKey: "features_jobSubNavs_jobQuality",
  },
}
export const receivingSubNavs: FeatureNavs = {
  receivingGeneral: {
    icon: null,
    route: receivingSubRoutes.RCV_GENERAL,
    translatedTextKey: "features_receivingSubNavs_receivingGeneral",
  },
  receivingDetails: {
    icon: null,
    route: receivingSubRoutes.RCV_DETAILS,
    translatedTextKey: "features_receivingSubNavs_receivingDetails",
  },
}
export const inventorySubNavs: FeatureNavs = {
  inventoryDetails: {
    icon: null,
    route: inventorySubRoutes.INVENTORY_DETAILS,
    translatedTextKey: "features_inventorySubNavs_inventoryDetails",
  },
  inventoryActivity: {
    icon: null,
    route: inventorySubRoutes.INVENTORY_ACTIVITY,
    translatedTextKey: "features_inventorySubNavs_inventoryActivity",
  },
}
export const moveInventorySubNavs: FeatureNavs = {
  inventoryMove: {
    icon: null,
    route: moveInventorySubRoutes.MOVE_INVENTORY_MOVE,
    translatedTextKey: "features_moveInventorySubNavs_inventoryMove",
  },
  inventoryHistory: {
    icon: null,
    route: moveInventorySubRoutes.MOVE_INVENTORY_HISTORY,
    translatedTextKey: "features_moveInventorySubNavs_inventoryHistory",
  },
}
export const warehouseLocationSubNavs: FeatureNavs = {
  locationDetails: {
    icon: null,
    route: warehouseLocationSubRoutes.WAREHOUSE_LOCATIONS_DETAILS,
    translatedTextKey: "features_warehouseLocationSubNavs_locationDetails",
  },
  locationInventory: {
    icon: null,
    route: warehouseLocationSubRoutes.WAREHOUSE_LOCATIONS_INVENTORY,
    translatedTextKey: "features_warehouseLocationSubNavs_locationInventory",
  },
}
export const shippingSubNavs: FeatureNavs = {
  orders: {
    icon: null,
    route: shippingSubRoutes.SHIPPING_ORDERS,
    translatedTextKey: "features_shippingSubNavs_orders",
  },
  shipments: {
    icon: null,
    route: shippingSubRoutes.SHIPPING_SHIPMENTS,
    translatedTextKey: "features_shippingSubNavs_shipments",
  },
}
export const shippingModalSubNavs: FeatureNavs = {
  shippingPick: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_PICK,
    translatedTextKey: "features_shippingModalSubNavs_shippingPick",
  },
  shippingPack: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_PACK,
    translatedTextKey: "features_shippingModalSubNavs_shippingPack",
  },
  shippingShip: {
    icon: null,
    route: shippingModalSubRoutes.SHIPPING_SHIP,
    translatedTextKey: "features_shippingModalSubNavs_shippingShip",
  },
}

export interface MenuItemProps {
  icon: React.ReactNode
  translatedTextKey: string
  route: string
  show?: string
}

// The MenuLists control that the tabs are showing up at the top of each page
const DashboardMenuList: MenuItemProps[] = [featureNavs.dashboard]

const FinanceMenuList: MenuItemProps[] = [featureNavs.purchase_orders]

const ReportingMenuList: MenuItemProps[] = [featureNavs.reports]

const OpsMenuList: MenuItemProps[] = [
  featureNavs.items,
  featureNavs.inventory,
  featureNavs.receiving,
  featureNavs.production,
  featureNavs.shipping,
]

const SalesMenuList: MenuItemProps[] = [featureNavs.quotes, featureNavs.orders]

const PeopleMenuList: MenuItemProps[] = [
  featureNavs.companies,
  featureNavs.contacts,
  featureNavs.users,
]

const SettingsMenuList: MenuItemProps[] = [
  featureNavs.settings,
  featureNavs.permissionsBilling,
  featureNavs.customFields,
  featureNavs.integrations,
  featureNavs.operations,
  featureNavs.tools,
  featureNavs.warehouseLocations,
  featureNavs.customLabels,
  featureNavs.customDocs,
  featureNavs.customForms,
]

export const ItemSubMenuList: MenuItemProps[] = [
  itemSubNavs.itemDetails,
  itemSubNavs.itemVariants,
  itemSubNavs.itemVendors,
  itemSubNavs.itemPurchasing,
  itemSubNavs.itemProduction,
  itemSubNavs.itemInventory,
  itemSubNavs.itemSales,
  itemSubNavs.itemAutomations,
]
export const ItemProductionSubMenuList: MenuItemProps[] = [
  itemSubNavs.itemProductionBom,
  itemSubNavs.itemProductionRouting,
  itemSubNavs.itemProductionHistory,
]

export const ReceivingSubMenuList: MenuItemProps[] = [
  receivingSubNavs.receivingGeneral,
  receivingSubNavs.receivingDetails,
]

export const InventorySubMenuList: MenuItemProps[] = [
  inventorySubNavs.inventoryDetails,
  inventorySubNavs.inventoryActivity,
]

export const MoveInventorySubMenuList: MenuItemProps[] = [
  moveInventorySubNavs.inventoryMove,
  moveInventorySubNavs.inventoryHistory,
]

export const JobSubMenuList: MenuItemProps[] = [
  jobSubNavs.jobDetails,
  jobSubNavs.jobProduction,
  jobSubNavs.jobMaterials,
  jobSubNavs.jobQuality,
]

export const WarehouseLocationSubMenuList: MenuItemProps[] = [
  warehouseLocationSubNavs.locationDetails,
  warehouseLocationSubNavs.locationInventory,
]

export const ShippingSubMenuList: MenuItemProps[] = [
  shippingSubNavs.orders,
  shippingSubNavs.shipments,
]
export const ShippingModalSubMenuList: MenuItemProps[] = [
  shippingModalSubNavs.shippingPick,
  shippingModalSubNavs.shippingPack,
  shippingModalSubNavs.shippingShip,
]

export const SidebarMenuList = {
  home: DashboardMenuList,
  finance: FinanceMenuList,
  operations: OpsMenuList,
  people: PeopleMenuList,
  reporting: ReportingMenuList,
  sales: SalesMenuList,
  settings: SettingsMenuList,
}
